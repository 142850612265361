import React from "react";
import Marketingimg from "../Assets/MarketingPage.svg";
import "./HomePage.css";
import { useNavigate } from "react-router-dom";
import Nav from "components/Form/Nav";

const MarketingInsights = (id) => {
  const navigate = useNavigate();

  const handleExploreClick = () => {
    navigate("/");
  };

  return (
    <>
   <Nav />
    <div id={id} className="home-container">
    <div className="home-wrapper">
     
      <div className="home-us-content">
        <h1> Your Ultimate Source For Website Insights!</h1>
        <p>Unlock valuable website insights with in-depth analytics, performance tracking, and user behavior analysis to boost your online presence. Make data- driven decisions for enhanced user engagement and optimized website performance
        </p>

        <button className="explore-btn"  onClick={handleExploreClick} >Explore Now</button>
      </div>

      <div className="home-us-image">
        <img src={Marketingimg} alt="About Us" className="homeimg-fluid" />
      </div>
    </div>
  </div>
  </>
  );
};

export default MarketingInsights;