// import React from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap'; // Importing React Bootstrap components
// import './Choose.css'; // Import the CSS file for styling
// import choose from '../../Assets/choose.png';
// import ch1 from '../../Assets/ch1.svg';
// import ch2 from '../../Assets/ch2.svg';
// import ch3 from '../../Assets/ch3.svg';
// import ch4 from '../../Assets/ch4.svg';
// import ch5 from '../../Assets/ch5.svg';
// import ch6 from '../../Assets/ch6.svg';

// const Choose = () => {
//   return (
//     <section className="table-container">
//       <h2 className="table-heading">Why Choose Us</h2>

//       {/* React Bootstrap Layout for responsiveness */}
//       <Container fluid>
//         <Row className="justify-content-center">
//           {/* Left Column with 3 Cards */}
//           <Col md={4} sm={12} className="custom-margin">
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch1} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Detailed Domain & DNS Insights</h2>
//                 <p className="cardC-description">
//                   Our analysis gives you a complete view of your domain's health, including DNS configuration, IP info, Whois lookup, and DNS security extensions to ensure your site's integrity.
//                 </p>
//               </Card.Body>
//             </Card>
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch3} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Server & Hosting Diagnostics</h2>
//                 <p className="cardC-description">
//                   We help you assess server location, open ports, and trace routes for better network diagnostics, ensuring reliable uptime and faster load times.
//                 </p>
//               </Card.Body>
//             </Card>
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch5} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Performance Optimization</h2>
//                 <p className="cardC-description">
//                   Monitor global ranking , server health , quality metrics , and redirect chains to enhance user experience and improve website performance.
//                 </p>
//               </Card.Body>
//             </Card>
//           </Col>

//           {/* Image Column */}
//           <Col md={4} sm={12} className="text-center p-0 m-0">
//             <img src={choose} alt="Choose" className="chimg" />
//           </Col>

//           {/* Right Column with 3 Cards */}
//           <Col md={4} sm={12} className="custom-margin">
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch2} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Comprehensive Security Coverage</h2>
//                 <p className="cardC-description">
//                   We provide in-depth detection and prevention of security threats, including malware, phishing, firewall issues, and SSL/TLS vulnerabilities.
//                 </p>
//               </Card.Body>
//             </Card>
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch4} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Advanced Web Crawling & SEO Tools</h2>
//                 <p className="cardC-description">
//                   We optimize your site's SEO by analyzing crawl rules, linked pages, and social tags, giving you an edge in search engine visibility and user engagement.
//                 </p>
//               </Card.Body>
//             </Card>
//             <Card className="cardC">
//               <Card.Body>
//                 <div className="cardC-icon">
//                   <img src={ch6} alt="Cogs Icon" />
//                 </div>
//                 <h2 className="cardC-heading">Complete Security Protection</h2>
//                 <p className="cardC-description">
//                   Get a detailed view of your site's technical status with real-time updates on server status, security features, and potential threats, ensuring you stay ahead of potential issues.
//                 </p>
//               </Card.Body>
//             </Card>
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default Choose;



// import React from "react";
// import { Container, Row, Col } from "react-bootstrap"; // Importing Bootstrap
// import ChooseCard from "./ChooseCard"; // Import the new component
// import chooseData from "./chooseData"; // Import the data file
// import choose from "../../Assets/choose.png"; // Import image
// import "./Choose.css"; // Import CSS

// const Choose = () => {
//   return (
//     <section className="table-container">
//       <h2 className="table-heading">Why Choose Us</h2>

//       <Container fluid>
//         <Row className="justify-content-center">
//           {/* Left Column (First 3 Cards) */}
//           <Col md={4} sm={12} className="custom-margin">
//             {chooseData.slice(0, 3).map((item) => (
//               <ChooseCard
//                 key={item.id}
//                 icon={item.icon}
//                 title={item.title}
//                 description={item.description}
//               />
//             ))}
//           </Col>

//           {/* Middle Column (Image) */}
//           <Col md={4} sm={12} className="text-center p-0 m-0">
//             <img src={choose} alt="Choose" className="chimg" />
//           </Col>

//           {/* Right Column (Next 3 Cards) */}
//           <Col md={4} sm={12} className="custom-margin">
//             {chooseData.slice(3, 6).map((item) => (
//               <ChooseCard
//                 key={item.id}
//                 icon={item.icon}
//                 title={item.title}
//                 description={item.description}
//               />
//             ))}
//           </Col>
//         </Row>
//       </Container>
//     </section>
//   );
// };

// export default Choose;

import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import "./Choose.css";
import choose from "../../Assets/FrameChoose.svg";
import ch1 from "../../Assets/ch1.svg";
import ch2 from "../../Assets/ch2.svg";
import ch3 from "../../Assets/ch3.svg";
import ch4 from "../../Assets/ch4.svg";
import ch5 from "../../Assets/ch5.svg";
import ch6 from "../../Assets/ch6.svg";
import Nav from "components/Form/Nav";
// Data for the cards
const cardData = [
  {
    id: 1,
    icon: ch1,
    title: "Detailed Domain & DNS Insights",
    description:
      "Our analysis gives you a complete view of your domain's health, including DNS configuration, IP info, Whois lookup, and DNS security extensions to ensure your site's integrity.",
  },
  {
    id: 2,
    icon: ch2,
    title: "Comprehensive Security Coverage",
    description:
      "We provide in-depth detection and prevention of security threats, including malware, phishing, firewall issues, and SSL/TLS vulnerabilities.",
  },
  {
    id: 3,
    icon: ch3,
    title: "Server & Hosting Diagnostics",
    description:
      "We help you assess server location, open ports, and trace routes for better network diagnostics, ensuring reliable uptime and faster load times.",
  },
  {
    id: 4,
    icon: ch4,
    title: "Advanced Web Crawling & SEO Tools",
    description:
      "We optimize your site's SEO by analyzing crawl rules, linked pages, and social tags, giving you an edge in search engine visibility and user engagement.",
  },
  {
    id: 5,
    icon: ch5,
    title: "Performance Optimization",
    description:
      "Monitor global ranking, server health, quality metrics, and redirect chains to enhance user experience and improve website performance.",
  },
  {
    id: 6,
    icon: ch6,
    title: "Complete Security Protection",
    description:
      "Get a detailed view of your site's technical status with real-time updates on server status, security features, and potential threats, ensuring you stay ahead of potential issues.",
  },
];

// Card Component
const CardComponent = ({ icon, title, description }) => {
  return (
    <Card className="cardC">
      {/* <Card.Body> */}
      <div className="cardC-header mt-1">
        <img src={icon} alt={title} className="cardC-icon" />
        <h2 className="cardC-heading">{title}</h2>
      </div>
      {/* <h2 className="cardC-heading">{title}</h2> */}
      <p className="cardC-description mb-0">{description}</p>
      {/* </Card.Body> */}
    </Card>
  );
};

const Choose = (id) => {
  return (
    <>
      {/* <Nav /> */}
      <section id={id} className="table-container">
        <h2 className="table-heading">Why Choose Us</h2>

        <Container fluid>
          <Row className="justify-content-center">
            {/* Left Column */}
            <Col md={5} sm={12} className="custom-margin">
              {cardData.slice(0, 3).map((item) => (
                <CardComponent key={item.id} {...item} />
              ))}
            </Col>

            {/* Image Column */}
            <Col md={2} sm={12} className="text-center p-0 m-0 mb-0">
              <img src={choose} alt="Choose" className="chimg" />
            </Col>

            {/* Right Column */}
            <Col md={5} sm={12} className="custom-margin">
              {cardData.slice(3, 6).map((item) => (
                <CardComponent key={item.id} {...item} />
              ))}
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Choose;

