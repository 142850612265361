import React from "react";
import styled from "styled-components";
import colors from "styles/colors";

const StyledFooter = styled.footer`
  font-family: "Popins", sans-serif;
  color: white;
  bottom: 0;
  text-align: center;
  padding: 0.5rem 0;
  background: ${colors.backgroundLighter};
  display: flex;
  justify-content: center; /* Center the content horizontally */
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  // opacity: 0.75;
  // transition: all 0.2s ease-in-out;
  // &:hover {
  //   opacity: 1;
  // }

  @media (max-width: 480px) {
    min-width: 45vh;
  }
`;

// const Link = styled.a`
//   color: ${colors.primary};
//   font-weight: bold;
//   border-radius: 4px;
//   padding: 0.1rem;
//   transition: all 0.2s ease-in-out;
//   &:hover {
//     background: ${colors.primary};
//     color: ${colors.backgroundDarker};
//     text-decoration: none;
//   }
// `;

const RedmorphInc = styled.span`
  // margin: 0 0.5rem;
  font-family: "Popins", sans-serif;
  font-size: 0.9em; /* Adjust the size as needed */
  font-weight: 400;
  text-align: center;
  color:rgb(128, 128, 128); /* Red color */
  cursor: pointer;

  @media (max-width: 480px) {
    // min-width: 45vh;
    font-size: 0.7em;
  }
`;

const CopyrightSymbol = styled.span`
  font-weight: 400;
  font-size: 1.3em; /* Adjust the size as needed */
  margin-right: 0.2em;
  color:rgb(119, 119, 119); /* Add space to the right of the symbol */
  @media (max-width: 480px) {
    // min-width: 45vh;
    font-size: 1em;
  }
`;
const CopyrightYear = styled.span`
  color:rgb(128, 128, 128);
  font-size: 0.9em; /* Adjust the size as needed */
  font-weight: 400;

  @media (max-width: 480px) {
    // min-width: 45vh;
    font-size: 0.7em;
  }
`;

const Footer = (props: { isFixed?: boolean }): JSX.Element => {
  return (
    <StyledFooter style={props.isFixed ? { position: "relative" } : {}}>
      {/* <HorizontalLine/> */}
      <RedmorphInc
        onClick={() =>
          window.open("https://redmorph.com", "_blank", "noopener,noreferrer")
        }
      >
        RedMorph Inc.
      </RedmorphInc>
      <CopyrightSymbol>©</CopyrightSymbol>
      <CopyrightYear>
        {" "}
        2019-2025&nbsp;
      </CopyrightYear>
    </StyledFooter>
  );
};

export default Footer;
