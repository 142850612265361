import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { FaFacebook, FaTwitter, FaPinterest, FaInstagram } from "react-icons/fa";
import "./LastContact.css"; // Import custom styles
import ContactImage from "../../Assets/FrameContactUs.svg"; // Import image
import Nav from "components/Form/Nav"; // Import the navigation bar
import Footer from "components/misc/Footer";

const ContactUs = (id) => {
  return (<>
    {/* <Nav /> */}
    <div id={id} className="contact-us-container text-center py-5">
      {/* <Container> */}
      <Row className="justify-content-center">
        <Col md={6} className="text-center1 text-md-start">
          <Image src={ContactImage} alt="Contact Us" className="contact-image" fluid />
        </Col>
        <Col md={6} className="text-center ">
          <h2 className="fw-bold">Contact Us</h2>
          <p>For any inquiries or questions please write to us on</p>
          <a href="mailto:support@redmorph.com" className="contact-email">
            support@redmorph.com
          </a>
          <div className="social-icons">
            <p>Follow us</p>
            <div className="social-icons-row">
              <FaFacebook size={24} />
              <FaTwitter size={24} />
              <FaPinterest size={24} />
              <FaInstagram size={24} />
            </div>
          </div>
        </Col>
      </Row>
      {/* </Container> */}
    </div>
    <Footer />
  </>
  );
};

export default ContactUs;
