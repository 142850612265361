
import React, { useEffect } from "react";
import  './network.css';
import colors from "styles/colors";
const anychart = require("anychart");

interface Node {
  id: any;
  value: number;
  group: string;
  ipInfo?: any; // Optional if not always present
}
interface NetorkGraphProps {
  domainName: string; // Type can be adjusted
  crawlResult:any;
}

const NetworkGraph: React.FC<NetorkGraphProps> = ({crawlResult}) => {
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = crawlResult;
        const targetURL = data.targetURL;
        const externalPages = data.externalPages;
  
        const nodes: Node[] = [
          { id: targetURL, value: 1000000, group: "main" }, // Main node
        ];
  
        // Add external pages
        externalPages.forEach((page: { url: any; resolvedIP: any }, index: number) => {
          const node: Node = {
            id: page.url,
            value: 10000 + index * 5000,
            group: "external",  // Ensure group "external" is configured
            ipInfo: page.resolvedIP || "IP Unavailable",
          };
          nodes.push(node);
        });
  
        const edges: { from: any; to: any }[] = externalPages.map((page: { url: any }) => ({
          from: targetURL,
          to: page.url,
        }));
        
        const graphData = { nodes, edges };
        const chart = anychart.graph(graphData);

        // Define groups before assigning nodes
        chart.group("main").fill(colors.primary).normal().height(40);  // Main group color
        chart.group("external").fill("#06ff34").shape("triangle-up").normal().height(30); 
  
        chart.interactivity().zoomOnMouseWheel(true);
        
  
        chart.nodes().labels().enabled(true).format("{%id}").fontSize(12).fontWeight(600).fontFamily("Orbitron, sans-serif");
        chart.nodes().tooltip().format(function (this: any) {
          return `IP: ${this.getData("ipInfo")}`;
        });
  
        chart.layout().type("force");
  
        // Attach the chart to a container and draw it
        chart.container("networkGraphContainer");
        chart.draw();
  
        return () => {
          chart.dispose();
        };
      } catch (error) {
        // Handle error
      }
    };
  
    fetchData();
  }, [crawlResult]);

  const externalPages = crawlResult?.externalPages || [];

  return (
    <div>
       {externalPages.length > 0 ? (
        <div
          id="networkGraphContainer"
          style={{ width: "100%", height: "75vh", fontFamily: "Orbitron" }}
        ></div>
      ) : (
        <p style={{ textAlign: "center", padding:"20vh 0", color: "red", fontSize:"1rem", fontWeight: "bold" }}>
          {/* Crawl data is unavailable */}
        </p>
      )}
    </div>
  );
};

export default NetworkGraph;
