import { Route, Routes } from "react-router-dom";
import Styled from "styled-components";
import * as Sentry from "@sentry/react";
// import Home from "pages/Home";
// import Results from "pages/Results";
// import About from "pages/About";
import NotFound from "pages/NotFound";
import colors from "styles/colors";
import WebsiteX from "pages/Website-X";
// import Onboarding from "pages/Onboarding";
import Final from "pages/Final";
import AboutUs from "pages/LandingPage/components/aboutUs/AboutUs";
import ContactUs from "pages/LandingPage/components/contact/LastContact";
import Overview from "pages/LandingPage/components/overview/Overview";
import Choose from "pages/LandingPage/components/choose/Choose";
import { LandingPages } from "pages/LandingPage/LandingPages";
// import OnbordingTwo from "pages/OnbordingComp/OnbordingTwo";
// import AnimationComponent from "pages/OnbordingComp/Animation";
// import OnboardingOne from "pages/OnbordingComp/OnbordingOne";
// import OnbordingOne from "pages/OnbordingComp/OnbordingOne";
// import OnboardingThree from "pages/OnbordingComp/OnbordingThree";

const Container = Styled.main`
  // background: ${colors.background};
  color: ${colors.textColor};
  width: 100vw;
  height: 100vh;
  margin: 0;
  
`;

Sentry.init({
  // dsn: 'https://30eb6135d37643fb95c7da4e77a46142@glitch.as93.net/1',
  beforeSend(event) {
    // Check if error logging is disabled
    const ignoredHosts = ["localhost", "127.0.0.1"];
    const disableErrors = process.env.REACT_APP_DISABLE_ERROR_LOGGING;
    if (disableErrors || ignoredHosts.includes(window.location.hostname)) {
      return null;
    }
    return event;
  },
});

function App() {
  return (
    <Container>
      <Routes>
        <Route path="*" element={<NotFound />} />

        <Route path="/" element={<WebsiteX />} />
        {/* <Route path="/about" element={<About />} /> */}
        {/* <Route path="/results/:address" element={<Results />} /> */}
        {/* <Route
          path="/results/:scan_id"
          element={<Results results={undefined} scanId={""} domainName={""} />}
        /> */}
        {/* <Route path="/onboarding/:scan_id" element={<Onboarding />} /> */}
        <Route path="/results/:scan_id" element={<Final />} />
        {/* <Route path="/home" element={<Home />} /> */}
        <Route path="/aboutUs" element={<AboutUs />} />
        <Route path="/contactUs" element={<ContactUs />} />
        <Route path="/overviewPage" element={<Overview />} />
        <Route path="/ChooseUs" element={<Choose />} />
        <Route path="/landingPages" element={<LandingPages />} />
        <Route
          path="/privacyPolicy"
          element={
            <iframe
              src="/privacyPolicy.html"
              style={{ width: "100%", height: "100vh", border: "none" }}
            />
          }
        />
        <Route
          path="/termsConditions"
          element={
            <iframe
              src="/termsAndConditions.html"
              style={{ width: "100%", height: "100vh", border: "none" }}
            />
          }
        />

        {/* <Route path="/contact" element={<Contact />} /> */}
        {/* <Route path="/privacy-policy" element={<PrivacyPolicy />} /> */}
        {/* <Route path="/terms-and-conditions" element={<TermsAndConditions />} /> */}
      </Routes>
    </Container>
  );
}

export default App;
