import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import config from "../config";
import ResultsComponent from "./Results";
import "../styles/AnimationComponent.css";
import Navbar from "./Navbar/Navbar";

const Final: React.FC = () => {
  const location = useLocation();
  const { scanId, domainName, addressType, description } = location.state || {};
  // console.log("Location state: ", location.state);
  const api = process.env.REACT_APP_API_ENDPOINT || "/api";
  const [mtrResult, setMtrResult] = useState<any>(null);
  const [crawlResult, setCrawlResult] = useState<any>(null);
  const [results, setResults] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isBlurred, setIsBlurred] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch data logic here
        // setResults(crawlResult);
        // console.log("Fetching data...");
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false); // Set loading to false when fetch completes
      }
    };

    fetchData();
  }, [domainName, api]);

  const nodeRef = useRef(null);
  return (
    <>
      {isLoading && <Navbar />}
      <TransitionGroup component={null}>
        {/* <CSSTransition
          key={scanId}
          timeout={2000}
          classNames="slide"
          nodeRef={nodeRef} // Pass the ref here
        > */}
        <>
          {/* {isLoading ? (
              <div className="loading-spinner">
                <p>"Sending packets on a global tour... Just a moment!"</p>
                <div className="spinner" />
              </div>
            ) : results ? ( */}
          <ResultsComponent
            // results={results}
            scanId={scanId}
            domainName={domainName}
          />
        </>
        {/* </CSSTransition> */}
      </TransitionGroup>
    </>
  );
};

export default Final;

// import React, { useState, useRef, useEffect } from "react";
// import { useLocation } from "react-router-dom";
// import { CSSTransition, TransitionGroup } from "react-transition-group";
// // import config from "../config";
// import OnboardingOne from "./OnbordingComp/OnboardingOne";
// import OnboardingTwo from "./OnbordingComp/OnboardingTwo";
// import ResultsComponent from "./Results";
// import "../styles/AnimationComponent.css";
// import Navbar from "./Navbar/Navbar";

// const Final: React.FC = () => {
//   const location = useLocation();
//   const { scanId, domainName, addressType, description,skipOnboarding } = location.state || {};

//   const [mtrResult, setMtrResult] = useState<any>(null);
//   const [crawlResult, setCrawlResult] = useState<any>(null);
//   const [results, setResults] = useState<any>(null);
//   const [isLoading, setIsLoading] = useState(true);
//   const [isBlurred, setIsBlurred] = useState(false);
//   const [currentStep, setCurrentStep] = useState<number>(0);
//   const [onboardingComplete, setOnboardingComplete] = useState(false);
//   const api = process.env.REACT_APP_API_ENDPOINT || "/api";
//   // const showOnboarding = process.env.REACT_APP_ENABLE_ONBOARDING === "true";

//   const messages = [
//     "Finding your location... Just a moment!",
//     "Finding server location... Hang tight!",
//     // "Preparing to send packets... Almost there!",
//     "Sending packets on a global tour... Just a moment!",
//   ];

//   useEffect(() => {
//     const fetchData = async () => {
//       if (!domainName) return;

//       setIsLoading(true);

//       try {
//         const traceRoutePromise = fetch(
//           `${api}/trace-route?url=${domainName}`
//         ).then((res) => res.json());

//         const crawlPromise = fetch(`${api}/crawl?url=${domainName}`).then(
//           (res) => res.json()
//         );

//         const [traceRouteResult, crawlResult] = await Promise.all([
//           traceRoutePromise,
//           crawlPromise,
//         ]);

//         // console.log("Traceroute Result: ", traceRouteResult);
//         // console.log("Crawl Result: ", crawlResult);

//         setMtrResult(traceRouteResult);
//         setCrawlResult(crawlResult);

//         // const traceRoutePromise = fetch(
//         //   `${api}/trace-route?url=${domainName}`
//         // ).then((res) => res.json());

//         // const [traceRouteResult] = await Promise.all([
//         //   traceRoutePromise,
//         //   // crawlPromise,
//         // ]);

//         setMtrResult(traceRouteResult);

//         localStorage.setItem("crawlResult", JSON.stringify(crawlResult));
//         localStorage.setItem("mtrResult", JSON.stringify(traceRouteResult));

//         setResults(crawlResult);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       } finally {
//         setIsLoading(false); // Set loading to false when fetch completes
//       }
//     };

//     fetchData();
//   }, [domainName, api]);

//   const goToNextStep = () => {
//     if (currentStep < 1) {
//       setCurrentStep(currentStep + 1);
//     } else {
//       setOnboardingComplete(true);
//     }
//   };

//   const renderCurrentStep = () => {
//     switch (currentStep) {
//       case 0:
//         return (
//           <OnboardingOne
//             scanId={scanId}
//             domainName={domainName}
//             addressType={addressType}
//             mtrResult={mtrResult}
//             loading={!mtrResult}
//             onComplete={goToNextStep}
//             isBlurred={isBlurred}
//             description={description}
//           />
//         );
//       case 1:
//         const storedCrawlResult = JSON.parse(
//           localStorage.getItem("crawlResult") || "null"
//         );
//         return (
//           <OnboardingTwo
//             scanId={scanId}
//             domainName={domainName}
//             addressType={addressType}
//             crawlResult={storedCrawlResult}
//             loading={!storedCrawlResult}
//             onComplete={goToNextStep}
//             isBlurred={isBlurred}
//           />
//         );
//       // case 2:
//       //   return (
//       //     <OnboardingThree
//       //       scanId={scanId}
//       //       domainName={domainName}
//       //       addressType={addressType}
//       //       onComplete={goToNextStep}
//       //     />
//       //   );
//       default:
//         return null;
//     }
//   };
//   const nodeRef = useRef(null);
//   return (
//     <>
//       {isLoading && <Navbar />}
//       <TransitionGroup component={null}>
//         <CSSTransition
//           key={currentStep}
//           timeout={2000}
//           classNames="slide"
//           nodeRef={nodeRef} // Pass the ref here
//         >
//           <>
//             {/* Show loading spinner when loading, else show onboarding or results */}
//             {isLoading ? (
//               <div className="loading-spinner">
//                 <p>"Sending packets on a global tour... Just a moment!"</p>
//                 <div className="spinner" />
//               </div>
//             ) : !skipOnboarding && !onboardingComplete ? (
//               renderCurrentStep()
//             ) : results ? (
//               <ResultsComponent
//                 results={results}
//                 scanId={scanId}
//                 domainName={domainName}
//                 skipOnboarding={skipOnboarding}
//               />
//             ) : null}
//           </>
//         </CSSTransition>
//       </TransitionGroup>
//     </>
//   );
// };

// export default Final;
