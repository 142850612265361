import React from "react";
import { Card } from "components/Form/Card";
import NetworkGraph from "../../pages/NetworkGraphComp/NetworkGraph";

interface CrawlMapCardProps {
  data: any;
  title: string;
  icon: any;
  actionButtons: any;
}

const CrawlMapCard: React.FC<CrawlMapCardProps> = ({
  data,
  title,
  icon,
  actionButtons,
}) => {
  if (!data.externalPages || data.externalPages.length === 0) {
    return null;
  }

  return (
    <Card heading={title} icon={icon} actionButtons={actionButtons}>
      <div style={{ margin: "0.5rem 1.5rem" }}>
        <NetworkGraph domainName={data.targetURL} crawlResult={data} />
      </div>
    </Card>
  );
};

export default CrawlMapCard;
