import React from "react";

const OverviewCard = ({ image, title, description }) => {
  return (
    <div className="overview-col">
      <div className="card10">
        <div className="card-icon">
          <img src={image} alt="Card Icon" />
        </div>
        <h2 className="card-heading">{title}</h2>
        <p className="card-description">{description}</p>
      </div>
    </div>
  );
};

export default OverviewCard;
