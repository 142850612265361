import React, { useEffect, useState } from "react";
import { Element } from 'react-scroll';
import Home from "./Home";
import "./styles.css";

import Overview from "./LandingPage/components/overview/Overview";
import AboutUs from "./LandingPage/components/aboutUs/AboutUs";
import Choose from "./LandingPage/components/choose/Choose";
import ContactUs from "./LandingPage/components/contact/LastContact";
import Nav from "components/Form/Nav";
import MarketingInsights from "./LandingPage/components/HomePage";
import Footer from "components/misc/Footer";
// import Upgrade from "./LandingPage/components/upgrade/Upgrade";

const WebsiteHTML: React.FC = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
    };

    // Initial check on mount
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {/* <section> */}
        {/* <div> */}
        <Nav />
        <Home />
        {/* <Footer /> */}
        {/* <Element name="home">
        <Home id="home" />
      </Element>
      <Element name="aboutUs">
        <AboutUs id="aboutUs" />
      </Element>
      <Element name="overviewPage">
        <Overview id="overviewPage" />
      </Element>
      <Element name="contactUs">
        <ContactUs id="contactUs" />
      </Element>
      <Element name="chooseUs">
        <Choose id="chooseUs" />
      </Element>
      <Element name="chooseUs">
        <MarketingInsights id="Marketing" />
      </Element> */}
          {/* <Home id="home" />
          <Overview id="overview" />
          <AboutUs id="about-us" />
          <Choose id="choose" />
          <ContactUs id="contact-us" /> */}
           {/* <Upgrade/> */}
        {/* </div> */}
      {/* // </section> */}

      {/* <section>
        <div>
          <Home />
        </div>
      </section> */}
    </>
  );
};

export default WebsiteHTML;
