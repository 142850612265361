import React from 'react'
import { Element } from 'react-scroll';
import MarketingInsights from './components/HomePage'
import Overview from './components/overview/Overview'
import AboutUs from './components/aboutUs/AboutUs'
import Choose from './components/choose/Choose'
import ContactUs from './components/contact/LastContact'

export const LandingPages = () => {
  return (
    <>
      <Element name="home">
        <MarketingInsights id="home" />
      </Element>
      <Element name="overviewPage">
        <Overview id="overviewPage" />
      </Element>
      <Element name="aboutUs">
        <AboutUs id="aboutUs" />
      </Element>
      <Element name="chooseUs">
        <Choose id="chooseUs" />
      </Element>
      <Element name="contactUs">
        <ContactUs id="contactUs" />
      </Element>
    </>
  )
}
