// import React, { useEffect, useState, useRef } from "react";
// import styled from "styled-components";
// import colors from "../styles/colors";

// // Container for progress bar with responsive design
// const ProgressBarContainer = styled.div`
//   width: 100%; /* Take full width */
//   padding: 4px;
//   margin-top: 10px;
//   height: 2.2rem;
//   display: flex;
//   background-color: #ffffff; /* Background for the progress bar */
//   border: 1px solid #ccc; /* Optional border */
//   border-radius: 5px; /* Rounded edges */
//   overflow: hidden;
// `;

// // Block component
// const Block = styled.div<{ color: string }>`
//   height: 1.5rem;
//   background-color: ${({ color }) => color};
//   margin-right: 4px; /* Gap between blocks */
//   flex-grow: 1; /* Allow blocks to grow and shrink to fit the space */
// `;

// // BlockProgressBar component
// const BlockProgressBar = ({
//   passedPercentage,
//   loadingPercentage,
//   failedPercentage,
// }: {
//   passedPercentage: number;
//   loadingPercentage: number;
//   failedPercentage: number;
// }) => {
//   const [blockCount, setBlockCount] = useState(0);
//   const containerRef = useRef<HTMLDivElement>(null);

//   // Calculate the number of blocks based on container width
//   const calculateBlocks = () => {
//     if (containerRef.current) {
//       const containerWidth = containerRef.current.offsetWidth;
//       const blockWidth = 12; // Each block width (including margin)
//       const totalBlocks = Math.floor(containerWidth / blockWidth);
//       setBlockCount(totalBlocks);
//     }
//   };

//   useEffect(() => {
//     // Calculate the blocks on mount and when the window resizes
//     calculateBlocks();
//     window.addEventListener("resize", calculateBlocks);
//     return () => {
//       window.removeEventListener("resize", calculateBlocks);
//     };
//   }, []);

//   const passedBlocks = Math.floor((passedPercentage / 100) * blockCount);
//   const loadingBlocks = Math.floor((loadingPercentage / 100) * blockCount);
//   const failedBlocks = blockCount - passedBlocks - loadingBlocks;
//   console.log("failed")

//   const blocks = [
//     ...Array(passedBlocks).fill(colors.primary),
//     ...Array(loadingBlocks).fill("lightgrey"),
//     ...Array(failedBlocks).fill("red"),
//   ];

//   return (
//     <ProgressBarContainer ref={containerRef}>
//       <span
//         style={{
//           fontSize: 12,
//           color: "white",
//           backgroundColor: "#034772",
//           padding: "4px 4px 2px 4px",
//           display: 'flex',
//           marginRight: "4px",
//           whiteSpace: 'nowrap',
//           lineHeight: '1.2',
//         }}
//       >
//         Progress Bar
//       </span>
//       {blocks.map((color, index) => (
//         <Block key={index} color={color} />
//       ))}
//     </ProgressBarContainer>
//   );
// };

// export default BlockProgressBar;


import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import colors from "../styles/colors";

// Container for progress bar with responsive design
const ProgressBarContainer = styled.div`
  width: 100%; /* Take full width */
  padding: 4px;
  margin-top: 10px;
  height: 2.2rem;
  display: flex;
  background-color: #ffffff; /* Background for the progress bar */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 5px; /* Rounded edges */
  overflow: hidden;
`;
// Block component
const Block = styled.div<{ color: string }>`
  height: 1.5rem;
  background-color: ${({ color }) => color};
  margin-right: 4px; /* Gap between blocks */
  flex-grow: 1; /* Allow blocks to grow and shrink to fit the space */
`;

// BlockProgressBar component
const BlockProgressBar = ({
  passedPercentage,
  loadingPercentage,
  skippedPercentage,
  failedPercentage,
}: {
  passedPercentage: number;
  loadingPercentage: number;
  skippedPercentage: number;
  failedPercentage: number;
}) => {
  const [blockCount, setBlockCount] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);

  // Calculate the number of blocks based on container width
  const calculateBlocks = () => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const blockWidth = 12; // Each block width (including margin)
      const totalBlocks = Math.floor(containerWidth / blockWidth);
      setBlockCount(totalBlocks);
    }
  };

  useEffect(() => {
    // Calculate the blocks on mount and when the window resizes
    calculateBlocks();
    window.addEventListener("resize", calculateBlocks);
    return () => {
      window.removeEventListener("resize", calculateBlocks);
    };
  }, []);

  // Calculate the number of blocks for each type
  const passedBlocks = Math.floor((passedPercentage / 100) * blockCount);
  const loadingBlocks = Math.floor((loadingPercentage / 100) * blockCount);
  const skippedBlocks = Math.floor((skippedPercentage / 100) * blockCount);
  const failedBlocks = blockCount - passedBlocks - loadingBlocks - skippedBlocks;

  // Construct the blocks array, including skipped blocks
  const blocks = [
    ...Array(passedBlocks).fill(colors.primary),     // Passed blocks (primary color)
    ...Array(loadingBlocks).fill("lightgrey"),       // Loading blocks (grey color)
    ...Array(skippedBlocks).fill(colors.cblue),        // Skipped blocks (dark blue color)
    ...Array(failedBlocks).fill(colors.textColorSecondar),              // Failed blocks (red color)
  ];

  return (
    <ProgressBarContainer ref={containerRef}>
      <span
        style={{
          fontSize: 14,
          color: "white",
          backgroundColor: "#034772",
          padding: "4px 4px 2px 4px",
          display: 'flex',
          marginRight: "4px",
          whiteSpace: 'nowrap',
          lineHeight: '1.2',
        }}
      >
        Progress Bar
      </span>
      {blocks.map((color, index) => (
        <Block key={index} color={color} />
      ))}
    </ProgressBarContainer>
  );
};

export default BlockProgressBar;
