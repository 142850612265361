import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { geoMercator, geoPath } from "d3-geo";
import styled from "styled-components";
import { feature } from "topojson-client";
import { FeatureCollection } from "geojson";
import gpsLogo from "../../assets/location-pin.svg";
import serverpack from "../../assets/server-svgrepo.svg";
import serverWhite from "../../assets/server-svgrepo-white.svg";
import "../../styles/Maproute.css";

interface Location {
  latitude: number;
  longitude: number;
}

const MapRoute: React.FC<{
  mtrResult: any; // The API result
  loading: boolean; // The loading status
}> = ({ mtrResult, loading }) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [locations, setLocations] = useState<Location[]>([]);
  const [userCity, setUserCity] = useState();
  const [userCountry, setUserCountry] = useState<string>();
  const [serverCity, setServerCity] = useState<string>();
  const [serverCountry, setServerCountry] = useState<string>();

  useEffect(() => {
    if (!mtrResult || loading) {
      return;
    }
    // Extract latitude and longitude information from mtrResult
    if (mtrResult.result && mtrResult.result.length > 0) {
      const result = mtrResult.result;
      // console.log(result);

      const filteredResults = result.filter(
        (item: { type: string }) => item.type === "User IP"
      );
      // const userLocation = result[0];
      // setUserCity(userLocation?.geoInfo.city);
      // setUserCountry(userLocation?.geoInfo.country);

      const citiesAndCountries = filteredResults.map(
        (item: { geoInfo: { city: any; country: any } }) => {
          return {
            city: item.geoInfo.city,
            country: item.geoInfo.country,
          };
        }
      );
      const maxTextLength = 6;
      // Function to truncate text
      function truncateText(text: string, maxLength: number) {
        return text.length > maxLength
          ? text.slice(0, maxLength) + "..."
          : text;
      }

      setUserCity(citiesAndCountries[0].city);
      setUserCountry(
        truncateText(citiesAndCountries[0].country, maxTextLength)
      );
      if (
        result[result.length - 1].geoInfo &&
        !result[result.length - 1].geoInfo.error &&
        result[result.length - 1].geoInfo.latitude !== "N/A" &&
        result[result.length - 1].geoInfo.longitude !== "N/A"
      ) {
        const lastItem = result[result.length - 1];
        const lastCity = lastItem.geoInfo.city;
        const lastCountry = lastItem.geoInfo.country;
        const truncatedCountry = truncateText(lastCountry, maxTextLength);

        setServerCity(lastCity);
        setServerCountry(truncatedCountry);
      } else {
        const lastItem = result[result.length - 2];
        const lastCity = lastItem.geoInfo.city;
        const lastCountry = lastItem.geoInfo.country;

        setServerCity(lastCity);
        setServerCountry(lastCountry);
      }
      // console.log(serverCity);
      // console.log("User City:", userCity);
      // console.log("User Country:", userCountry);
      // console.log("Server City:", serverCity);
      // console.log("Server Country:", serverCountry);

      const mappedLocations = mtrResult.result
        .map((loc: any) => {
          const geoInfo = loc.geoInfo;
          if (
            geoInfo &&
            !geoInfo.error &&
            geoInfo.latitude !== "N/A" &&
            geoInfo.longitude !== "N/A"
          ) {
            return {
              latitude: geoInfo.latitude,
              longitude: geoInfo.longitude,
            };
          }
          return null;
        })
        .filter((loc: any) => loc !== null);

      setLocations(mappedLocations);
    }
  }, [loading, mtrResult]);

  useEffect(() => {
    if (locations.length === 0) return;

    const width = 700;
    const height = 500;

    const svg = d3
      .select(svgRef.current)
      .attr("viewBox", `0 0 ${width} ${height}`)
      .attr("preserveAspectRatio", "xMidYMid meet");

    const projection = geoMercator()
      .scale(100)
      .translate([width / 2, height / 1.3]);
    const path = geoPath().projection(projection);

    svg.selectAll("*").remove();

    d3.json("https://unpkg.com/world-atlas/countries-50m.json").then(
      (data: any) => {
        const countries = feature(
          data,
          data.objects.countries
        ) as unknown as FeatureCollection;

        svg
          .selectAll(".country")
          .data(countries.features)
          .enter()
          .append("path")
          .attr("class", "country")
          .attr("d", path as any)
          .attr("fill", "#075283")
          .attr("stroke", "#fff")
          .attr("stroke-width", 0.5);

        const locationsGroup = svg.append("g").attr("class", "locations");
        locations.forEach((location, i) => {
          const [x, y] = projection([
            location.longitude,
            location.latitude,
          ]) as [number, number];
          // Check if it's the starting point
          if (i === 0) {
            locationsGroup
              .append("image")
              .attr("xlink:href", gpsLogo) // Use startingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);

            locationsGroup
              .append("rect")
              .attr("x", x - 50)
              .attr("y", y - 35) // Position slightly above the image
              .attr("width", 130)
              .attr("height", 20)
              .attr("fill", "white")
              .attr("rx", 4);
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.parentNode as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.parentNode as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "12px"); // Revert the text size back
            // });

            locationsGroup
              .append("text")
              .attr("x", x) // Position text in the center horizontally
              .attr("y", y - 20) // Position text above the image
              .attr("text-anchor", "middle") // Align text to center
              .attr("font-size", "12px")
              .attr("fill", "#000")
              .text(`${userCity},${userCountry}`);
          }
          // Check if it's the ending point
          else if (i === locations.length - 1) {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverWhite) // Use endingPointIcon
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
            locationsGroup
              .append("rect")
              .attr("x", x - 30)
              .attr("y", y - 55) // Position slightly above the image
              .attr("width", 130)
              .attr("height", 20)
              .attr("fill", "white")
              .attr("rx", 4);
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "12px"); // Revert the text size back
            // });

            locationsGroup
              .append("text")
              .attr("x", x + 30) // Position text in the center horizontally
              .attr("y", y - 40) // Position text above the image
              .attr("text-anchor", "middle") // Align text to center
              .attr("font-size", "12px")
              .attr("fill", "#000")
              .text(`${serverCity},${serverCountry}`);
          }
          // For intermediate points
          else {
            locationsGroup
              .append("image")
              .attr("xlink:href", serverWhite) // Use default gpsLogo
              .attr("x", x - 12)
              .attr("y", y - 12)
              .attr("width", 20)
              .attr("height", 20);
            // locationsGroup
            // .append("rect")
            // .attr("x", x-30)
            // .attr("y", y - 55) // Position slightly above the image
            // .attr("width", 150)
            // .attr("height", 20)
            // .attr("fill", "white")
            // .attr("rx", 4)
            // .on("mouseover", function () {
            //   // Use d3 to select the associated text using the same data or an identifier
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition() // Add a transition effect
            //     .duration(200) // Time in ms for the effect
            //     .attr("font-size", "14px"); // Enlarge the text size
            // })
            // .on("mouseout", function () {
            //   d3.select(this.nextSibling as Element).select("text") // Select the sibling text element inside the same parent
            //     .transition()
            //     .duration(200)
            //     .attr("font-size", "12px"); // Revert the text size back
            // });

            // locationsGroup
            // .append("text")
            // .attr("x", x+50) // Position text in the center horizontally
            // .attr("y", y - 40) // Position text above the image
            // .attr("text-anchor", "middle") // Align text to center
            // .attr("font-size", "12px")
            // .attr("fill", "#000")
            // .text(`${serverCity},${serverCountry}`);
            // locationsGroup
            // .append("text")
            // .attr("x", x) // Position text in the center horizontally
            // .attr("y", y - 20) // Position text above the image
            // .attr("text-anchor", "middle") // Align text to center
            // .attr("font-size", "12px")
            // .attr("fill", "#000")
            // .text(locationText);
          }
        });

        // Create paths (arcs) between locations
        const linePaths: d3.Selection<
          SVGPathElement,
          unknown,
          SVGSVGElement,
          unknown
        >[] = [];
        locations.forEach((location, i) => {
          if (i < locations.length - 1) {
            const source = location;
            const target = locations[i + 1];

            // Use a custom arc generator to create rounder arcs
            const arcGenerator = (
              source: [number, number],
              target: [number, number]
            ) => {
              const dx = target[0] - source[0];
              const dy = target[1] - source[1];
              const dr = Math.sqrt(dx * dx + dy * dy);
              return `M${source[0]},${source[1]}A${dr},${dr} 0 0,1 ${target[0]},${target[1]}`;
            };

            const sourcePos = projection([
              source.longitude,
              source.latitude,
            ]) as [number, number];
            const targetPos = projection([
              target.longitude,
              target.latitude,
            ]) as [number, number];

            const linePath = svg
              .append("path")
              .attr("class", "arc")
              .attr("d", arcGenerator(sourcePos, targetPos))
              .attr("fill", "none")
              .attr("stroke", "#FF3131")
              .attr("stroke-width", 1.8)
              .attr("stroke-dasharray", "5,5");

            linePaths.push(linePath as any);
          }
        });

        // Animate the plane along the arcs
        if (linePaths.length > 0) {
          const planeImage = svg
            .append("image")
            .attr("xlink:href", serverWhite)
            .attr("width", 30)
            .attr("height", 30)
            .attr("opacity", 0);

          const textElement = svg
            .append("text")
            .attr("font-size", "12px")
            .attr("fill", "#000");

          const animateAlongPath = (pathIndex: number) => {
            const linePath = linePaths[pathIndex];
            const totalLength = linePath.node()?.getTotalLength() ?? 0;

            planeImage
              .attr("opacity", 1)
              .attr("transform", `translate(-15, -15)`);

            planeImage
              .transition()
              .duration(1500)
              .attrTween("transform", function () {
                return function (t: number) {
                  const point = linePath
                    .node()
                    ?.getPointAtLength(t * totalLength);
                  if (point) {
                    // Update the plane position
                    const transform = `translate(${point.x - 15}, ${
                      point.y - 15
                    })`;

                    // Update the text position above the plane
                    // textElement
                    //   .attr("x", point.x)
                    //   .attr("y", point.y - 25) // Adjust this to position the text above the image
                    //   .text("Server Location");

                    return transform;
                  }
                  return "";
                };
              })
              .ease(d3.easeLinear)
              .on("end", () => {
                if (pathIndex < linePaths.length - 1) {
                  animateAlongPath(pathIndex + 1);
                } else {
                  planeImage.transition().duration(500).attr("opacity", 0);
                }
              });
          };

          animateAlongPath(0);
        }
      }
    );
  }, [locations]);

  return (
    <>
      <svg ref={svgRef} className="map" preserveAspectRatio="xMidYMid meet" />
      <LegendContainer>
        <LegendTitle>Legends</LegendTitle>
        <LegendItem>
          <LegendIcon src={gpsLogo} alt="Your Location" />
          <LegendText>Your Location</LegendText>
          <VLine />
          <LegendIcon src={serverpack} alt="Server Location" />
          <LegendText>Server Location</LegendText>
        </LegendItem>
      </LegendContainer>
    </>
  );
};

export default MapRoute;

const LegendContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #075283;
  position:relative;

  margin-top: -2rem;
  margin-left: 20%;
  // padding: 1rem;
  // background-color: #f9f9f9;
  border-radius: 8px;
  max-width: 300px;

  @media (max-width: 1500px) {
      margin-top: 0rem ! important;
    }
  @media (max-width: 1100px) {
      margin-left:35%
      margin-top: 1rem;
    }

    @media (max-width: 768px) {
    
      margin-left:30%
    }

  @media (max-width: 550px) {
      margin-left:15%
    }

`;

const LegendTitle = styled.div`
  font-size: 0.7rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
`;

const LegendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75%;
`;

const LegendIcon = styled.img`
  width: 15px;
  height: 15px;
  // margin-right: 0.5rem;
`;

const LegendText = styled.div`
  font-size: 0.55rem;
  font-weight: 600;
  // margin-right: 0.5rem;
  letter-spacing: 0.5px;
`;

const VLine = styled.div`
  height: 24px;
  width: 1px;
  background-color: #ccc;
  // margin: 0 0.5rem;
`;
