import React from "react";
import { Card } from "components/Form/Card";
import * as Flags from "country-flag-icons/react/3x2";
import styled from "styled-components";

interface CrawlUrlsCardProps {
  data: any;
  title: string;
  icon: any;
  actionButtons: any;
}

type CountryCode = keyof typeof Flags;

const getCountryFlagComponent = (countryCode: string | number) => {
  if (
    typeof countryCode === "string" &&
    (countryCode as CountryCode) in Flags
  ) {
    const FlagIcon = Flags[countryCode as CountryCode];
    return <FlagIcon style={{ width: "30px", height: "10px" }} />;
  } else {
    return <span>🏳️</span>; // Default to neutral flag if country code is not found
  }
};

const UrlItem = styled.div`
  .item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    cursor: pointer;
    position: relative;
  }

  .item:hover::after {
    overflow: hidden;

    content: attr(data-full-url);
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    padding: 5px;
    z-index: 1;
    white-space: normal;
    max-width: 300px;
    word-wrap: break-word;
    right: 0px;
  }
`;

const CrawlUrlsCard: React.FC<CrawlUrlsCardProps> = ({
  data,
  title,
  icon,
  actionButtons,
}) => {
  if (!data.allUrls || data.allUrls.length === 0) {
    return null;
  }

  return (
    <Card heading={title} icon={icon} actionButtons={actionButtons}>
      <div style={{ margin: "0.5rem 1.5rem" }}>
        {data.allUrls.map((item: any, index: number) => {
          if (item && item.geoInfo) {
            return (
              <UrlItem key={index}>
                <div className="item" data-full-url={item.url}>
                  {getCountryFlagComponent(item.geoInfo.countryCode)}-{" "}
                  {item.url}
                </div>
              </UrlItem>
            );
          } else {
            return null;
          }
        })}
      </div>
    </Card>
  );
};

export default CrawlUrlsCard;
