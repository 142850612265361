// import styled from "styled-components";
// import xlogo from '../../assets/Xlogo.png';
// import red from '../../assets/rm-logo.png';
// import { Card1 } from "components/Form/Card1";
// // import Heading from "components/Form/Heading";
// // import colors from "styles/colors";
// import { ReactNode } from "react";
// import HamburgerMenu from "pages/HamburgerMenu";
// // import { useNavigate } from "react-router-dom";

// const Header = styled(Card1)`
//   margin: 0.2rem auto;
//   display: flex;
//   flex-wrap: wrap;
//   align-items: baseline;
//   justify-content: space-between;
//   padding: 0.5rem 1rem;
//   align-items: center;
//   width: 95vw;
//   @media (max-width: 761px) {
//   display: flex;
//   flex-direction: column;

// }

// `;

// const Nav = (props: { children?: ReactNode }) => {

//   // const navigate = useNavigate();  // useNavigate hook in React Router v6

//   // // const goToHome = () => {
//   // //   navigate('/', { replace: true }); // Replaces the current entry in history stack with home
//   // // };

//   return (
//     <Header as="header">
//       <div style={{ textAlign: "center", display:'flex'}}>
//         {/* <a onClick={goToHome}> */}
//         <a href="/">
//           <img width="150" src={red} alt="Redmorph Icon" />
//         </a>
//         {/* <div style={{ height: '30px', width: '1px', backgroundColor: 'black', marginTop:'1rem'}}></div> */}
//         {/* <img width="55" height='45' src={xlogo} alt="Redmorph Icon" style={{marginTop:'0.7rem',paddingLeft:'10px'}}/> */}

//       </div>
//       <HamburgerMenu/>
//       {/* <Heading color={colors.primary} size="large">
//       <img width="64" src="/web-check.png" alt="Web Check Icon" />
//       <a href="/">Web Check</a>
//       <a href="/">Redmorph X</a>
//     </Heading> */}
//       {props.children && props.children}
//     </Header>
//   );
// };

// export default Nav;

import styled from "styled-components";
import { useLocation } from "react-router-dom";
import xlogo from "../../assets/Xlogo.png";
import red from "../../assets/rm-logo.png";
import { Card1 } from "components/Form/Card1";
import { ReactNode } from "react";
import HamburgerMenu from "pages/HamburgerMenu";

const Header = styled(Card1)`
  margin: 0.2rem auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  width: 95vw;
  // position: fixed;
  // top: 0;
  // left: 40px;
  z-index: 9999;

  @media (max-width: 761px) {
    flex-direction: row;
  }
  @media (max-width: 480px) {
    // gap: 4rem;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
`;

const HamburgerWrapper = styled.div`
  margin-left: auto; /* Pushes the hamburger to the right */
  display: flex;
  align-items: center;
`;

const Nav = (props: { children?: ReactNode }) => {
  const location = useLocation();
  return (
    <Header as="header">
      <LogoContainer>
        <a href="/">
          <img width="150" src={red} alt="Redmorph Icon" />
        </a>
      </LogoContainer>

      {!location.pathname.startsWith("/results") && (
        <HamburgerWrapper>
          <HamburgerMenu />
        </HamburgerWrapper>
      )}

      {props.children}
    </Header>
  );
};

export default Nav;
