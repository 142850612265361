import React from 'react';
import './AboutUs.css';
import aboutimg from '../../Assets/OBJECTS.svg';
import Nav from 'components/Form/Nav'; // <-- Import the navigation bar

const AboutUs = (id) => {
  return (
    <>
      {/* <Nav /> This will render the navbar in front */}
      <div id={id} className="about-container">
        <div className="about-wrapper">
          <div className="about-us-image">
            <img src={aboutimg} alt="About Us" className="img-fluid" />
          </div>
          <div className="about-us-content">
            <h2>About Us</h2>
            <p>
              At RedMorph, We bring over three decades of expertise in website optimization, security, and real-time monitoring.
              With years of experience and a deep understanding of the digital landscape, privacy, and security, we provide businesses with a powerful platform that consolidates all the tools needed to ensure their websites run securely, efficiently, and at peak performance.
            </p>
            <p>
              Our comprehensive solutions cover everything from advanced security features to in-depth domain and SEO analysis, empowering you to stay ahead of the competition.
              Trusted by businesses worldwide, we are committed to delivering innovative, reliable, and customer-focused services that help your website thrive in an ever-changing digital world.
            </p>
            <p>
              Our platform is designed to simplify website management while providing robust protection against threats, ensuring that your site remains resilient against cyberattacks.
              We continuously evolve our services to meet the latest digital trends and challenges, helping you maintain a competitive edge in a rapidly changing market. With intuitive dashboards and actionable insights, we empower you to make data-driven decisions for sustained growth.
            </p>
            <p>
              Our expert support team is always ready to assist, providing personalized solutions tailored to your unique needs.
              Trust us to optimize, secure, and enhance your online presence, so you can focus on what matters most—growing your business.
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;