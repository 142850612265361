import React, { useState } from "react";
// import { Link } from 'react-scroll';
import "./HamburgerMenu.css"; // adjust the path as necessary
import { Link as RouterLink, useLocation } from "react-router-dom"; // For navigation
import { Link as ScrollLink } from "react-scroll";

const HamburgerMenu = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
    document.body.classList.toggle("menu-open");
  };
  // Check if the current page is home
  const isHomePage = location.pathname === "/";

  // const scrollToSection = (id: string) => {
  //   const section = document.getElementById(id);
  //   if (section) {
  //     section.scrollIntoView({ behavior: "smooth", block: "start" });
  //     setIsOpen(false); // Close the menu after clicking
  //   }
  // };

  return (
    <>
      {/* <header className="site-navbar">
        <div className="container">
          <div className="row">
            <div className="col-md-2 col-6">
              <div className="brand brand-logo">
                <img
                  src="./assets1/images/rm-logo-white-svg.png"
                  alt=""
                  className="img-fluid logo-img"
                />
              </div>
            </div>
          </div>
        </div>
      </header> */}
      <div>
        <div>
          <button
            className={`menu-button ${isOpen ? "open" : ""}`}
            onClick={toggleMenu}
          >
            <div className="hamburger-icon">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
        </div>
        <ul className={`menu-items ${isOpen ? "open" : ""}`}>
          {isHomePage ? (
            <>
              <li className="menu-item">
                <RouterLink
                  to="/landingPages"
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={toggleMenu}
                >
                  About Us
                </RouterLink>
              </li>
              <li className="menu-item">
                <RouterLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/privacyPolicy"
                  onClick={toggleMenu}
                >
                  Privacy Policy
                </RouterLink>
              </li>
              <li className="menu-item">
                <RouterLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/termsConditions"
                  onClick={toggleMenu}
                >
                  Terms & Conditions
                </RouterLink>
              </li>
            </>
          ) : (
            <>
              <li className="menu-item">
                <RouterLink to="/" onClick={toggleMenu}>
                  Home
                </RouterLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="overviewPage"
                  smooth={true}
                  duration={100}
                  onClick={toggleMenu}
                >
                  Overview
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="aboutUs"
                  smooth={true}
                  duration={100}
                  onClick={toggleMenu}
                >
                  About Us
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="chooseUs"
                  smooth={true}
                  duration={100}
                  onClick={toggleMenu}
                >
                  Why Choose Us
                </ScrollLink>
              </li>
              <li className="menu-item">
                <ScrollLink
                  to="contactUs"
                  smooth={true}
                  duration={100}
                  onClick={toggleMenu}
                >
                  Contact Us
                </ScrollLink>
              </li>
              <li className="menu-item">
                <RouterLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/privacyPolicy"
                  onClick={toggleMenu}
                >
                  Privacy Policy
                </RouterLink>
              </li>
              <li className="menu-item">
                <RouterLink
                  target="_blank"
                  rel="noopener noreferrer"
                  to="/termsConditions"
                  onClick={toggleMenu}
                >
                  Terms & Conditions
                </RouterLink>
              </li>
            </>
          )}
        </ul>
      </div>
    </>
  );
};

export default HamburgerMenu;
