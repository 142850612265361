
// import React from 'react';
// import './Overview.css';
// import card1 from '../../Assets/c1.svg';
// import card2 from '../../Assets/c2.svg';
// import card3 from '../../Assets/c3.svg';
// import card4 from '../../Assets/c4.svg';
// import card5 from '../../Assets/c5.svg';
// import card6 from '../../Assets/c6.png';

// const Overview = () => {
//   return (
//     <div className="overview-container container-fluid">
//       <h1 className="overview-heading">Advanced Web Diagnostics & Security Overview</h1>
//       <div className="overview-row row row-cols-1 row-cols-md-2 row-cols-lg-2 g-4 custom-margin-left">


//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card1} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Server & Hosting Information</h2>
//             <p className="card-description">
//               Contain server-specific details, including the server’s physical or geographic
//               location, open ports for communication, and trace routes for network diagnostics and
//               performance analysis
//             </p>
//           </div>
//         </div>
//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card2} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Website Performance & Quality Metrics</h2>
//             <p className="card-description">
//               Involves performance and quality assessments, including global website ranking, server
//               health, URL redirects, and overall site features that impact user experience and site
//               functionality
//             </p>
//           </div>
//         </div>
//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card3} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Tracking & Data Collection</h2>
//             <p className="card-description">
//               Focuses on tracking, user data collection, and interlinking within the site. It includes
//               cookies for tracking, social media integration, and linked or listed pages for SEO and
//               usability
//             </p>
//           </div>
//         </div>
//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card4} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Web Crawling & Configuration</h2>
//             <p className="card-description">
//               Deals with crawling, email & other configurations. It includes settings that guide web
//               crawlers and configurations for email servers that help in communication and data gathering
//             </p>
//           </div>
//         </div>
//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card5} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Domain & DNS Information</h2>
//             <p className="card-description">
//               Key domain and DNS-related information, including DNS configurations, domain ownership, IP
//               addresses, and security features like DNSSEC and TXT records for domain validation.
//               <p>&nbsp;</p>
//             </p>
//           </div>
//         </div>
//         <div className="overview-col">
//           <div className="card10">
//             <div className="card-icon">
//               <img src={card6} alt="Cogs Icon" />
//             </div>
//             <h2 className="card-heading">Website Security & Protection</h2>
//             <p className="card-description">
//               Focuses on security measures that protect the website from various attacks such as malware,
//               phishing, and unauthorized access. It includes HTTPS security configurations, SSL certificates,
//               and firewall analysis.
//             </p>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default Overview;



import React from "react";
import "./Overview.css";
import card1 from "../../Assets/c1.svg";
import card2 from "../../Assets/c2.svg";
import card3 from "../../Assets/c3.svg";
import card4 from "../../Assets/c4.svg";
import card5 from "../../Assets/c5.svg";
import card6 from "../../Assets/Security-lock.svg";
// import OverviewCard from "./OverviewCard"; 
import OverviewCard from "./OverviewCard";
import Nav from "components/Form/Nav";

const overviewData = [
  {
    id: 1,
    image: card1,
    title: "Server & Hosting Information",
    description:
      "Contain server-specific details, including the server’s physical or geographic location, open ports for communication, and trace routes for network diagnostics and performance analysis.",
  },
  {
    id: 2,
    image: card2,
    title: "Website Performance & Quality Metrics",
    description:
      "Involves performance and quality assessments, including global website ranking, server health, URL redirects, and overall site features that impact user experience and site functionality.",
  },
  {
    id: 3,
    image: card3,
    title: "Tracking & Data Collection",
    description:
      "Focuses on tracking, user data collection, and interlinking within the site. It includes cookies for tracking, social media integration, and linked or listed pages for SEO and usability.",
  },
  {
    id: 4,
    image: card4,
    title: "Web Crawling & Configuration",
    description:
      "Deals with crawling, email & other configurations. It includes settings that guide web crawlers and configurations for email servers that help in communication and data gathering.",
  },
  {
    id: 5,
    image: card5,
    title: "Domain & DNS Information",
    description:
      "Key domain and DNS-related information, including DNS configurations, domain ownership, IP addresses, and security features like DNSSEC and TXT records for domain validation.",
  },
  {
    id: 6,
    image: card6,
    title: "Website Security & Protection",
    description:
      "Focuses on security measures that protect the website from various attacks such as malware, phishing, and unauthorized access. It includes HTTPS security configurations, SSL certificates, and firewall analysis.",
  },
];

const Overview = (id) => {
  return (
    <>
      {/* <Nav /> */}
      <div className="overview-container fluid">
        <p className="overview-heading">
          Advanced Web Diagnostics & Security Overview
        </p>
        <div className="overview-row row row-cols-1 g-4 ">
          {overviewData.map((item) => (
            <OverviewCard
              key={item.id}
              image={item.image}
              title={item.title}
              description={item.description}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Overview;
